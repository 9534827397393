jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		},
		hooks: {
			// Add off-canvas hook to handle menu close event
			"close:after": function() {
				$mobileMenuTrigger.removeClass("w--open");
			},
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");

		return false;
	});

	API.bind('close:finish', function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	// Fit Vids
	if ($("body").innerWidth() <= 767) {
		$(".inside-content-section").fitVids({ ignore: "nofit" });
		$(".homeboxes").fitVids({ ignore: "nofit" });
	}

	// Webflow inline javascript
	! function(o, c) {
		var n = c.documentElement,
			t = " w-mod-";
		n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
	}(window, document);


	var interactions = [];
	interactions = interactions.concat([
		{ slug: "dropdown-hover", name: "Dropdown Hover", value: { style: {}, triggers: [{ type: "hover", stepsA: [{ display: "block" }], stepsB: [{ display: "none" }] }] } },
		{ slug: "new-dropdown", name: "New Dropdown", value: { style: {}, triggers: [{ type: "dropdown", stepsA: [{ opacity: 0, height: "0px" }, { opacity: 1, height: "auto", transition: "opacity 200ms ease 0ms, height 200ms ease 0ms" }], stepsB: [{ height: "0px", transition: "opacity 100ms ease 0ms, height 100ms ease 0ms" }] }] } },
		{ slug: "dropdown-list", name: "Dropdown List ", value: { style: {}, triggers: [{ type: "hover", stepsA: [{ display: "block" }], stepsB: [{ display: "none" }] }] } },
		{ slug: "nav-search-button", name: "Nav Search Button", value: { style: {}, triggers: [{ type: "click", stepsA: [{ display: "block" }], stepsB: [{ display: "none" }] }, { type: "click", stepsA: [{ opacity: 1 }], stepsB: [{ opacity: 0.55 }] }] } },
		{ slug: "md-reveal-doctor-search", name: "MD Reveal Doctor Search", value: { style: {}, triggers: [{ type: "click", stepsA: [{ display: "block", height: "0px" }, { height: "auto", transition: "height 500ms ease 0ms" }], stepsB: [{ height: "0px", transition: "height 500ms ease 0ms" }, { display: "none" }] }] } },
		{ slug: "md-tooltip-button-hover", name: "MD Tooltip Button Hover", value: { style: {}, triggers: [{ type: "hover", descend: true, stepsA: [{ display: "block", opacity: 0, x: "0px", y: "1.5rem", z: "0px" }, { wait: 150 }, { opacity: 1, transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms", x: "0px", y: "0px", z: "0px" }], stepsB: [{ wait: 150 }, { opacity: 0, transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms", x: "0px", y: "1.5rem", z: "0px" }, { display: "none", opacity: 1, x: "0px", y: "0px", z: "0px" }] }, { type: "click", descend: true, stepsA: [{ display: "block", opacity: 0, x: "0px", y: "1.5rem", z: "0px" }, { wait: 150 }, { opacity: 1, transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms", x: "0px", y: "0px", z: "0px" }], stepsB: [{ opacity: 0, transition: "transform 250ms ease 0ms, opacity 250ms ease 0ms", x: "0px", y: "1.5rem", z: "0px" }, { display: "none", opacity: 1, x: "0px", y: "0px", z: "0px" }] }] } },
		{ slug: "reveal-left-nav", name: "Reveal Left Nav", value: { style: {}, triggers: [{ type: "click", selector: ".left-nav-list-content-wrap", siblings: true, stepsA: [{ height: "auto", transition: "height 500ms ease 0ms" }], stepsB: [{ height: "0px", transition: "height 500ms ease 0ms" }] }] } },
		{ slug: "md-patient-comments-reveal-on-load", name: "MD Patient Comments Reveal (on load)", value: { style: { height: "0px" }, triggers: [] } },
		{ slug: "md-show-more-button", name: "MD Show More Button", value: { style: {}, triggers: [{ type: "click", stepsA: [{ height: "auto", transition: "height 700ms ease 0ms" }], stepsB: [] }] } },
		{ slug: "loc-reveal-sidebar-search", name: "LOC Reveal Sidebar Search", value: { style: {}, triggers: [{ type: "click", selector: ".loc-sidebar-form-reveal", stepsA: [{ height: "auto", transition: "height 500ms ease 0ms" }], stepsB: [{ height: "0px", transition: "height 500ms ease 0ms" }] }, { type: "click", selector: ".loc-refine-search-button-image", preserve3d: true, stepsA: [{ transition: "transform 500ms ease 0ms", rotateX: "0deg", rotateY: "0deg", rotateZ: "90deg" }], stepsB: [{ transition: "transform 500ms ease 0ms", rotateX: "0deg", rotateY: "0deg", rotateZ: "0deg" }] }] } },
		{ slug: "for-two-part-button-toggle", name: "For Two Part Button Toggle", value: { style: {}, triggers: [{ type: "click", selector: ".two-part-button-list", siblings: true, preserve3d: true, stepsA: [{ transition: "transform 750ms ease 0", x: "-100%", y: "0px", z: "0px" }], stepsB: [] }] } },
		{ slug: "for-two-part-button-list", name: "For Two Part Button List", value: { style: { x: "100%", y: "0px", z: "0px" }, triggers: [] } },
		{ slug: "for-two-part-list-back-button", name: "For Two Part List Back Button", value: { style: {}, triggers: [{ type: "click", selector: ".two-part-button-list", preserve3d: true, stepsA: [{ transition: "transform 750ms ease 0", x: "100%", y: "0px", z: "0px" }], stepsB: [] }] } },
		{ slug: "display-none-on-page-load", name: "Display None On Page Load", value: { style: { display: "none" }, triggers: [] } },
		{ slug: "for-grid-view-button", name: "For Grid View Button", value: { style: { opacity: 0.7500000000000002 }, triggers: [{ type: "click", selector: ".shop-item-row-wrap-list-view", stepsA: [{ display: "none" }], stepsB: [] }, { type: "click", selector: ".shop-item-row-wrap-grid-view", stepsA: [{ display: "flex" }], stepsB: [] }, { type: "click", selector: ".pagination-view-by-button", siblings: true, stepsA: [{ opacity: 0.3500000000000001, transition: "opacity 200ms ease 0ms" }], stepsB: [] }, { type: "click", stepsA: [{ opacity: 0.7500000000000002, transition: "opacity 200ms ease 0ms" }], stepsB: [] }] } },
		{ slug: "for-list-view-button", name: "For List View Button", value: { style: {}, triggers: [{ type: "click", selector: ".shop-item-row-wrap-grid-view", stepsA: [{ display: "none" }], stepsB: [] }, { type: "click", selector: ".shop-item-row-wrap-list-view", stepsA: [{ display: "flex" }], stepsB: [] }, { type: "click", selector: ".pagination-view-by-button", siblings: true, stepsA: [{ opacity: 0.3500000000000001, transition: "opacity 200ms ease 0ms" }], stepsB: [] }, { type: "click", stepsA: [{ opacity: 0.7500000000000002, transition: "opacity 200ms ease 0ms" }], stepsB: [] }] } },
		{ slug: "expand-search-bar", name: "Expand Search Bar", value: { style: {}, triggers: [] } },
		{ slug: "preloader-wrap", name: "Preloader Wrap", value: { style: { display: "flex" }, triggers: [{ type: "load", preload: true, stepsA: [{ wait: 750 }, { opacity: 0, transition: "opacity 750ms ease 0ms" }, { display: "none" }], stepsB: [] }] } },
		{ slug: "mobile-search-reveal", name: "Mobile Search Reveal", value: { style: {}, triggers: [{ type: "click", stepsA: [{ height: "0px" }, { height: "auto", transition: "height 500ms ease 0ms" }], stepsB: [{ height: "0px", transition: "height 500ms ease 0ms" }] }, { type: "click", selector: ".secondary-nav-icon", descend: true, stepsA: [{ opacity: 0, transition: "opacity 200 ease 0" }, { display: "none" }], stepsB: [{ display: "block", opacity: 0 }, { opacity: 0.4, transition: "opacity 200 ease 0" }] }, { type: "click", selector: ".secondary-nav-icon-search-close", descend: true, stepsA: [{ display: "block", opacity: 0 }, { opacity: 0.4, transition: "opacity 200 ease 0" }], stepsB: [{ opacity: 0, transition: "opacity 200 ease 0" }, { display: "none" }] }] } },
		{ slug: "hide-mobile-header-on-load", name: "Hide Mobile Header On Load", value: { style: { height: "0px" }, triggers: [] } },
		{ slug: "header-language-hover", name: "Header Language Hover", value: { style: {}, triggers: [{ type: "hover", descend: true, stepsA: [{ wait: "150ms", display: "block" }], stepsB: [{ wait: "150ms", display: "none" }] }] } },
		{ slug: "desktop-search-button-interaction", name: "Desktop Search Button Interaction", value: { style: {}, triggers: [{ type: "click", selector: ".nav-link-search-icon", descend: true, stepsA: [{ opacity: 0, transition: "opacity 200 ease 0" }, { display: "none" }], stepsB: [{ display: "block", opacity: 0 }, { opacity: 1, transition: "opacity 200 ease 0" }] }, { type: "click", selector: ".nav-link-search-close", descend: true, stepsA: [{ display: "block", opacity: 0 }, { opacity: 1, transition: "opacity 200 ease 0" }], stepsB: [{ opacity: 0, transition: "opacity 200 ease 0" }, { display: "none" }] }, { type: "click", stepsA: [{ height: "auto", transition: "height 350ms ease 0" }], stepsB: [{ height: "0px", transition: "height 200 ease 0" }] }] } }
	]);
	Webflow.require('ix').init(interactions);

	// Accessible menu
	$(".nav-link.active-item").parent(".nav-drop-toggle").addClass("active-li");

	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-link").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".nav-drop-toggle").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".nav-drop-toggle").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	$(".desktop-navigation-menu").accessibleMenu();

	/* Sticky header */
	$(window).scroll(function() {
		$('.nav-section').toggleClass("sticky", $(this).scrollTop() > 30);
	});
});
